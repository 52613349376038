
import dayjs from "dayjs";
import { ResponseParams } from "@/types/response";
import { defineComponent, reactive, ref, onMounted } from "vue";
import { getEmployeeCalendarDetails } from "@/api/request-modules/iteration";

interface CalendarItems {
  assigned_bug_num?: number;
  complete_work_num?: number;
  date?: string;
  fixed_bug_num?: number;
  staffName?: string;
  staff_no?: string;
  submit_bug_num?: number;
  work_num?: number;
  list: Array<{
    end?: string;
    hours?: number;
    name?: string;
    product_id: number;
    product_name: string;
    real_end?: string;
    real_start?: string;
    start?: string;
    status?: string;
    type: number;
  }>;
}

export default defineComponent({
  name: "PeopleCell",
  components: {},
  props: {
    // 日期类型
    type: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    // 横向索引值
    rowIndex: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    // 纵向索引值
    columnIndex: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    // 员工编号
    staffNo: {
      type: String,
      default: () => {
        return "";
      }
    },
    // 员工名称
    staffName: {
      type: String,
      default: () => {
        return "";
      }
    },
    // 当前月份
    certainDay: {
      type: String,
      default: () => {
        return "";
      }
    },
    year: {
      type: String,
      default: () => {
        return dayjs().format("YYYY");
      }
    }
  },
  emits: ["change"],
  setup(props) {
    const loading = ref(false);
    let calendarDetails = reactive<CalendarItems>({
      list: [],
      date: "",
      staff_no: "",
      staffName: "",
      work_num: 0,
      fixed_bug_num: 0,
      submit_bug_num: 0,
      assigned_bug_num: 0,
      complete_work_num: 0
    });
    const today = ref(dayjs().format("MM/DD"));

    onMounted(() => {
      getCalendarDetails(props.staffNo, props.certainDay);
    });

    const getCalendarDetails = (staff_no: string, certain_day: string) => {
      loading.value = true;
      if (props.type == 2) {
        getEmployeeCalendarDetails<ResponseParams.ResponseDataSuccess>({
          staff_no: staff_no,
          certain_day: certain_day,
          year: props.year
        }).then((res) => {
          if (res.code === 200 && res.data) {
            const { list, date, staff_no, staffName, work_num, fixed_bug_num, submit_bug_num, assigned_bug_num, complete_work_num } = res.data;
            calendarDetails.list = list;
            calendarDetails.date = date;
            calendarDetails.staff_no = staff_no;
            calendarDetails.staffName = staffName;
            calendarDetails.work_num = work_num;
            calendarDetails.fixed_bug_num = fixed_bug_num;
            calendarDetails.submit_bug_num = submit_bug_num;
            calendarDetails.assigned_bug_num = assigned_bug_num;
            calendarDetails.complete_work_num = complete_work_num;
          } else {
            calendarDetails = {
              list: [],
              date: "",
              staff_no: "",
              staffName: "",
              work_num: 2,
              fixed_bug_num: 0,
              submit_bug_num: 0,
              assigned_bug_num: 2,
              complete_work_num: 0
            };
          }
          loading.value = false;
        });
      }
    };

    const getDisplayTime = (start: string, end: string) => {
      if (start && end) {
        return `${start} - ${end}`;
      } else if (start && !end) {
        return `${start} - ${start}`;
      } else if (!start && end) {
        return `${end} - ${end}`;
      } else {
        return "";
      }
    };

    return {
      today,
      loading,
      calendarDetails,
      getDisplayTime
    };
  }
});
